const theme = {
    colors: {
        primary: '#FCCE5E',
        primaryDark: '#FBB819',
        black: '#333',
        primaryContrast: '#FFFFFF',
    }
}


export default theme;