import Layout from "../../layout/default";
import { Link } from 'react-router-dom';
import Button from "../../components/Button";
import Routes from "./Routes";




const IntroductionGame = () => {

    return <Layout>
        <h1>Introduction game!</h1>
        <Routes />
    </Layout>
}

export default IntroductionGame