import image1 from '../assets/avatars/1.png'
import image2 from '../assets/avatars/2.png'
import image3 from '../assets/avatars/3.png'
import image4 from '../assets/avatars/4.png'
import image5 from '../assets/avatars/5.png'
import image6 from '../assets/avatars/6.png'

const avatars = [
    { _id: 0, image: image1 },
    { _id: 1, image: image2 },
    { _id: 2, image: image3 },
    { _id: 3, image: image4 },
    { _id: 4, image: image5 },
    { _id: 5, image: image6 },
];

export default avatars;