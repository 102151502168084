import styled from "styled-components";

const Button = styled.button`
    cursor: pointer;
    background-color: ${props => props.theme.colors.primaryContrast};
    border: none;
    padding: 20px 40px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 21px;
    margin-top: 40px;
`;

export default Button;