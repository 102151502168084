import { useSocketContext } from "../context/socketContext"
import { Controller, useForm } from "react-hook-form";
import Input from "@material-ui/core/Input";
import { FormGroup, FormControl, InputLabel, IconButton, Button, TextField } from "@material-ui/core";
import Layout from "../layout/default";



const StartForm = () => {
    const { control, handleSubmit } = useForm();

    const onSubmit = data => onJoinRoom(data);
    const { establishConnection } = useSocketContext();

    const onJoinRoom = (data) => {
        const { roomCode, userName } = data
        if (roomCode && userName) {
            establishConnection && establishConnection({ userName: userName, roomCode: roomCode })
        }
    }

    return <div className="full-height"><div className="end-flex">
        <form onSubmit={handleSubmit(onSubmit)}>


            <FormControl>
                <Controller
                    name="userName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <TextField id="userName" label="userName" variant="outlined" {...field} />
                    }
                />
            </FormControl>
            <br />
            <FormControl>
                <Controller
                    name="roomCode"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <TextField id="roomcode" label="roomcode" variant="outlined" {...field} />
                    }
                />
            </FormControl>
            <br />
            <br />
            <Button type="submit" className="btn btn-primary" variant="contained">submit</Button>

            {/* <input type="submit" /> */}
        </form>
        {/* <div>
        <p>Roomcode:</p>
        <input type='text' value={roomCode} onChange={(e) => setRoomCode(e.target.value)}/>
    </div>
    <div>
        <p>Name:</p>
        <input type='text' value={userName} onChange={(e) => setUserName(e.target.value)}/>
    </div>
    <br/> */}
        {/* <button onClick={() => onJoinRoom()}> send</button> */}

    </div></div>
}

const HomePage = () => {

    return <Layout>
        <StartForm />
    </Layout>
}

export default HomePage