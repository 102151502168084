import { useSocketContext } from "../context/socketContext"
import Layout from "../layout/default";
import avatars from "../constants/avatars";
import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import Button from "../components/Button";
import { FormGroup, FormControl, InputLabel, IconButton, TextField } from "@material-ui/core";

const Container = styled.div`
    padding: 20px 0;
`

const AvatarWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`

const ImageWrap = styled.div`
    position: relative;
    margin-bottom: 30px;
    width: 150px;
    height: 150px;
    img {
        position: relative;
    }
    .background {
        background-color: white;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.selected {
        .background {
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        }
        h1 {
            color: ${props => props.theme.colors.black}
        }
        img {
            /* border: 2px solid ${props => props.theme.colors.black}; */
        }
        /* border: 2px solid black; */

    }
    &.not-available {
        filter: grayscale(80%);
        /* img {
            border: 5px solid red;
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                background-color: black;
                opacity: 0.4;
            }
        } */

    }
`
const AvatarImage = styled.img`
    flex: 50%;
    flex-grow: 0;
`

const AvatarSelection = () => {
    const { avatar, availableAvatars, onUpdateAvatar, selectAvatar, onGetSelectedAvatars } = useSocketContext();




    // const [avatarMap, setAvatarMap] = useState()

    // const getNewAvatarMap = useCallback(() => {
    //     if(availableAvatars) {
    //         console.log('getting new map');
    //         setAvatarMap([...avatars].map((avatar) => {avatar.available = !!availableAvatars.find((entry) => entry._id === avatar?._id); return avatar}));
    //     }
    // }, [availableAvatars])


    // useEffect(() => {
    //     if (avatar) {
    //         console.log(avatar, 'avatar Updated')
    //     }
    // }, [avatar])



    // useEffect(() => {
    //     if(availableAvatars) {
    //         getNewAvatarMap();
    //     }
    // }, [availableAvatars, getNewAvatarMap])

    // useEffect(() => {
    //     if(avatarMap) {
    //         console.log(avatarMap, 'hier is ie dan');
    //     }
    // }, [avatarMap])

    const getSelectedAvatars = useCallback(() => {

        onGetSelectedAvatars()

    }, [onGetSelectedAvatars])

    useEffect(() => {

        getSelectedAvatars();

    }, [])


    // 
    return (<Container>
        <h1>kies een dude</h1>
        <AvatarWrap>
            {availableAvatars && availableAvatars.map((avatarEntry) => <ImageWrap onClick={() => avatarEntry.available && onUpdateAvatar(avatarEntry)} key={avatarEntry._id} className={`${avatar?._id === avatarEntry._id && 'selected'} ${avatar?._id !== avatarEntry._id && !avatarEntry.available && 'not-available'}`}><div className="background"><AvatarImage src={avatarEntry.image} /></div></ImageWrap>)}
        </AvatarWrap>
        <Button onClick={() => selectAvatar()} type="submit" variant="contained">Kies</Button>

    </Container>
    )
}

const AvatarSelectionPage = () => {

    return <Layout>
        <AvatarSelection />
    </Layout>
}

export default AvatarSelectionPage