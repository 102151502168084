import Button from "../../components/Button"
// import { Button } from "@material-ui/core";
import { useSocketContext } from "../../context/socketContext";



import { useForm } from "react-hook-form";
import { useState } from "react";


// const ImageUploader = (props) => {
//     const inputRef = useRef();
//     console.log(props)
//     return (
//         <>
//             <input
//                 ref={inputRef}
//                 accept="image/*"
//                 style={{ display: 'none' }}
//                 // multiple
//                 type="file"
//                 onChange={(e) => props.onChange(e)}
//             />
//             <Button onClick={(e) => { e.preventDefault(); inputRef?.current?.click() }}>
//                 Upload
//             </Button>
//         </>
//     )
// }


const ImageUploadForm = () => {
    const { register, handleSubmit } = useForm();
    const { uploadImageResponse } = useSocketContext();
    const [imagePreview, setImagePreview] = useState();

    const updateImagePreview = (file) => {
        console.log(file);
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImagePreview(reader.result);
            });
            reader.readAsDataURL(file);
        }

    }

    const onUpdate = (data) => {
        console.log(data);
        const file = data?.picture[0];
        file && updateImagePreview(file);
    };

    const onSubmit = (data) => {
        console.log(data);
        const file = data?.picture && data.picture[0] && new Blob([data?.picture[0]], { type: data?.picture[0].type });
        console.log(file)
        file && uploadImageResponse(file);

        // uploadImageResponse(file);
        // const reader = new FileReader();
        // reader.addEventListener("load", () => {
        //     // uploadImageResponse(reader.result);
        //     uploadImageResponse(file);
        // });
        // reader.readAsDataURL(file);
        // const storageRef = app.storage().ref();
        // const fileRef = storageRef.child(file.name);
        // fileRef.put(file).then(() => {
        //   console.log("Uploaded a file");
        // });
    };

    return <div className="full-height">
        <form onChange={handleSubmit(onUpdate)} onSubmit={handleSubmit(onSubmit)}>
            <div>
                <img src={imagePreview} alt='fototje' />
            </div>
            <input
                {...register('picture', { required: true })}
                name="picture"
                type="file" />

            <input type="submit" />

        </form>
    </div >
}

const Upload = () => {
    const { onNextScene } = useSocketContext();
    // todo: call to update response for current game & current round
    // current game is available and it's id is sufficed in the context.
    // to upload a photo the flow will be different
    // do api call to cdn, cdn will give response with url, save this url to db
    return (
        <>
            <p>Dit is waar de gebruiker geprompt zal worden om een foto te uploaden</p>


            <ImageUploadForm />
        </>
    )
}

export default Upload;