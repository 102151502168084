
const Introduction = () => {
    return (
        <>
            dit is de introduction game, scherm 1
            op de stream is momenteel uitleg
        </>
    )
}

export default Introduction;