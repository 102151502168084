import { useSocketContext } from "../context/socketContext"
import { Controller, useForm } from "react-hook-form";
import Input from "@material-ui/core/Input";
import { FormGroup, FormControl, InputLabel, IconButton, Button, TextField } from "@material-ui/core";
import Layout from "../layout/default";



const LobbyPage = () => {

    return <Layout>
        <p> welcome to the lobby :^ )</p>
    </Layout>
}

export default LobbyPage