import { useCallback, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import Introduction from './Introduction';
import Upload from './Upload';
import Review from './Review';



const ProtectedRoute = ({ component: Component, ...props }) => {

  return (
    <Route
      {...props}
      render={props => (
        true ?
          <Component {...props} /> :
          <Redirect to='/' />
      )}
    />
  )
}

const Routes = () => {

  return (<Switch>
    <Route exact path="/introductionGame" component={Introduction} />
    <ProtectedRoute path="/introductionGame/upload" component={Upload} />
    <ProtectedRoute path="/introductionGame/review" component={Review} />
  </Switch>
  );
}


export default Routes;