import styled from "styled-components";

const Container = styled.div`
    background-color: ${(props) => props?.theme?.colors?.primary};
    color: ${(props) => props?.theme?.colors?.primaryContrast};
    min-height: 100vh;
    input {
        background: white;
    border-radius: 5px;
    }
    .full-height {
        height: 100vh;
    }
    .center-flex {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }
    .end-flex {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        height: 100%;
    }
    form {
        margin-bottom: 50px;
        > div {
            margin: 15px;
        }
    }
`

const Layout = ({children}) => {
    return (
        <Container>
            {children}
        </Container>
    )
}

export default Layout