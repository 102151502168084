import Button from "../../components/Button"
import { useSocketContext } from "../../context/socketContext";

const Upload = () => {
    const { onNextScene } = useSocketContext();
    return (
        <>
            <p>op dit moment is er een speler aan het praten</p>
            <p>wellicht op scherm nog wat mogelijk heden geven om te interacteren</p>
            <p>of wat info over de speler</p>
        </>
    )
}

export default Upload;