import { useState, createContext, useContext, useEffect, useCallback } from 'react';
import { io } from 'socket.io-client';
import avatars from '../constants/avatars'
import axios from 'axios';

const Socketcontext = createContext({});
const apiBase = 'http://192.168.0.114:3111';

const SocketProvider = ({ children }) => {
    const [userName, setUserName] = useState();
    const [roomCode, setRoomCode] = useState();
    // const [gameId, setGameId] = useState();
    const [userId, setUserId] = useState();
    const [avatar, setAvatar] = useState();
    const [availableAvatars, setAvailableAvatars] = useState();

    const [selectedAvatars, setSelectedAvatars] = useState([]);

    const [clientView, setClientView] = useState('/')
    const [currentGame, setCurrentGame] = useState()
    // const updateAvailableAvatars = () => {
    //     const available = [...avatars].filter((avatar) => !selectedAvatars.find((avatarEntry) => avatarEntry._id === avatar._id));
    //     // console.log(available, 'here are the available avatars')
    //     setAvailableAvatars(available);
    // }

    const uploadImageResponse = (image) => {
        console.log(image, 'this is what I get here')
        //todo: fix round
        const formData = new FormData();
        formData.append('upload', image, image.filename || 'piet');

        if (currentGame?.id && userId) {
            const url = `${apiBase}/upload?gameId=${currentGame.id}&round=2&userId=${userId}&room=${roomCode}`;

            axios.post(url,
                formData,
                {
                    headers: {
                        'Content-Type': image.type
                    }
                })
                .then(function (response) {
                    console.log(response);
                })
        } else {
            console.log('not all parameters set to post')
        }
    }


    const onUpdateAvatar = (selectedAvatar) => {
        console.log('selectedAvatar:', selectedAvatar);

        console.log(selectedAvatar, availableAvatars?.indexOf(selectedAvatar) !== -1)

        if (availableAvatars?.indexOf(selectedAvatar) !== -1) {
            setAvatar(selectedAvatar);
            // change to work with player id for the pulling of previously selected avatars
            const payload = {
                avatar: selectedAvatar._id,
                userId: userId,
                room: roomCode
            }
            socket.emit("setSelectedAvatar", payload)
            // socket.emit("setSelectedAvatar", { previousAvatar: avatar, id: userId, selectedAvatar: { ...selectedAvatar }, room: roomCode });
        }

    }

    const onChangeClientView = (route) => {
        console.log('do it');
        setClientView(route);
    }

    const [view, setView] = useState();

    // selected scene logic
    const [socket, setSocket] = useState();

    // const onUpdateScene = (newScene) => setScene(newScene);
    // useEffect(() => {
    //     const newSocket = io.connect(`localhost:3000`)
    //     newSocket.on("connect", () => setConnected(true));
    //     newSocket.on("setRoom", (room) => setRoom(room));
    //     // newSocket.on("broadcastToRoom", (room) => console.log('we are in', room))
    //     setSocket(newSocket);

    //     return () => newSocket.close();
    // }, [setSocket]);

    const connect = useCallback(() => {
        const newSocket = io.connect(`192.168.0.114:3110`, {
            query: {
                socketId: socket?.id || ''
            }
        });
        console.log('this event is also triggered')
        newSocket.emit("joinRoom", { room: roomCode, userName: userName });

        setSocket(newSocket);

    }, [userName, roomCode]);

    useEffect(() => {
        if (userName && userName.length > 0 && roomCode && !socket) {
            connect();
        }
        return () => socket && socket.close();
    }, [userName, roomCode, connect, socket])

    const onGetSelectedAvatars = () => {
        socket && socket.emit('getSelectedAvatars', { room: roomCode })
    }

    const initSocketEvents = useCallback(() => {

        console.log('initSocketEvents')
        socket.on("broadcast", (message) => console.log(message))
        socket.on("changeClientView", (route) => { console.log('this is not emited'); onChangeClientView(route) })
        socket.on("setUserId", (newUserId) => !userId && setUserId(newUserId))

        socket.on("updateSelectedAvatars", () => {
            socket.emit('getSelectedAvatars', { room: roomCode })
        })
        socket.on("setSelectedAvatars", (selectedAvatars) => { console.log('this gets emitted'); setSelectedAvatars(selectedAvatars) })
        socket.on("setCurrentGame", (newGame) => { setCurrentGame(newGame) })
    }, [socket, roomCode]);

    const initReconnectEvents = useCallback(() => {
        // console.log('userId', userId);
        userId && socket.io.on("reconnect_attempt", () => {
            console.log('how this work?', userId)
            socket.io.opts.query = {
                socketId: userId || ''
            }
        });


        roomCode && socket.io.on("reconnect", () => {
            console.log('reconnect is called')
            socket.emit('reconnectRoom', { room: roomCode })
        });



    }, [userId, socket, roomCode]);


    useEffect(() => {
        if (userId) {
            console.log(userId, 'dit is die user id')
            initReconnectEvents()
        }
    }, [userId, roomCode, initReconnectEvents])

    useEffect(() => {
        if (socket) {
            initSocketEvents();
        }
    }, [socket, initSocketEvents]);

    useEffect(() => {
        console.log('change on currentGame', currentGame)
    }, [currentGame])

    const OnSelectedChange = useCallback(() => {
        // updateAvailableAvatars();
        // const available = [...avatars].filter((avatar) => !selectedAvatars.find((avatarEntry) => avatarEntry._id === avatar._id));
        const available = [...avatars].map((avatar) => { const newAvatar = { ...avatar }; const isAvailable = !selectedAvatars.find((avatarEntry) => avatarEntry.avatarId === avatar._id); newAvatar['available'] = isAvailable; return newAvatar; });
        console.log(available, 'here are the available avatars')
        setAvailableAvatars(available);

    }, [selectedAvatars])

    useEffect(() => {
        if (selectedAvatars) {
            console.log('selected as changed', selectedAvatars)
            // hier dan de update van available avatars
            // setAvailableAvatars(null);
            OnSelectedChange();
        }
    }, [selectedAvatars, OnSelectedChange]);

    useEffect(() => {
        if (socket && userId && userName && roomCode) {
            console.log('update user');
            socket.emit("setUserName", { id: userId, userName: userName, room: roomCode });
        }
    }, [socket, userId, userName, roomCode]);



    const establishConnection = ({ userName, roomCode }) => {
        setRoomCode(roomCode);
        setUserName(userName);
        // const newSocket = io.connect(`localhost:3000`);
    }

    const selectAvatar = () => {
        avatar && socket.emit("selectAvatar", { room: roomCode, userId: userId });
    }

    const AvatarData = {
        avatar, onUpdateAvatar, availableAvatars, selectAvatar, onGetSelectedAvatars
    }

    const inputData = {
        uploadImageResponse
    }



    return (
        <Socketcontext.Provider value={{ userId, socket, clientView, establishConnection, ...AvatarData, ...inputData }}>
            {children}
        </Socketcontext.Provider>
    );
};

const useSocketContext = () => useContext(Socketcontext);

export { useSocketContext };

export default SocketProvider;