import { useCallback, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useSocketContext } from './context/socketContext';
import IntroductionGame from './games/introductionGame';
import AvatarSelectionPage from './pages/AvatarSelection';
import HomePage from './pages/Home';
import LobbyPage from './pages/Lobby';

const RouteComp = () => {
  return <p>hallo</p>
}


const ProtectedRoute = ({ component: Component, ...props }) => {
  // const {userId} = useSocketContext();

  return (
    <Route
      {...props}
      render={props => (
        true ?
          <Component {...props} /> :
          <Redirect to='/' />
      )}
    />
  )
}

const Routes = () => {
  const { clientView } = useSocketContext();
  const history = useHistory();

  const updateRoute = useCallback(() => {
    console.log(clientView, 'I try to push the route');
    history?.push(clientView);

  }, [history, clientView])

  useEffect(() => {
    if (clientView) {
      console.log(clientView, 'I got updated here');
      updateRoute();
    }
  }, [clientView, updateRoute]);

  return (<Switch>
    <Route exact path="/" component={HomePage} />
    <ProtectedRoute path="/avatar-selection" component={AvatarSelectionPage} />
    <ProtectedRoute path="/lobby" component={LobbyPage} />
    <ProtectedRoute path="/introductionGame" component={IntroductionGame} />
  </Switch>
  );
}


export default Routes;