import logo from './logo.svg';
import './App.css';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import SocketProvider from './context/socketContext';
import {ThemeProvider} from 'styled-components';
import theme from './theme';

function App() {
  
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <SocketProvider>
            <Routes/>
          </SocketProvider>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
